var render = function () {
  var _vm$item$str, _vm$item$email, _vm$item$firstName, _vm$item$lastName, _vm$item$languages, _vm$item$clientAgeHan;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "0",
      "pb": "20px",
      "paths": [{
        label: 'Managemen Ahli Gizi',
        href: '/admin/nutritionists/'
      }, {
        label: 'Detail Ahli Gizi',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mb": ['0', '16px'],
      "p": ['1rem', '2rem 4rem'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "mb": "30px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" Detail Ahli Gizi ")]), _c('c-box', [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "h": "auto",
      "min-w": "114px",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "as": "router-link",
      "to": {
        name: 'admin.nutritionists.edit',
        params: {
          id: _vm.id
        }
      }
    }
  }, [_c('c-box', {
    attrs: {
      "mr": "10px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-edit.svg'),
      "height": "20px",
      "width": "20px",
      "fill": "#FFF"
    }
  })], 1), _vm._v(" Edit ")], 1)], 1)], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Data Pribadi ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('c-box', {
    attrs: {
      "mb": "43px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "6px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Foto Ahli Gizi ")]), _c('c-image', {
    attrs: {
      "src": _vm.getPhotoUser(_vm.item.photoUrl),
      "w": "150px",
      "h": "150px",
      "alt": "avatar",
      "border-radius": "50%",
      "object-fit": "cover"
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nomor STR ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$str = _vm.item.str) !== null && _vm$item$str !== void 0 ? _vm$item$str : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Email ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$email = _vm.item.email) !== null && _vm$item$email !== void 0 ? _vm$item$email : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$firstName = _vm.item.firstName) !== null && _vm$item$firstName !== void 0 ? _vm$item$firstName : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$lastName = _vm.item.lastName) !== null && _vm$item$lastName !== void 0 ? _vm$item$lastName : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Gelar ")]), _vm._l(_vm.item.education, function (edu, index) {
    var _edu$title;

    return _c('c-text', {
      key: 'gelar' + index,
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s((_edu$title = edu.title) !== null && _edu$title !== void 0 ? _edu$title : '-') + " ")]);
  })], 2), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Pendidikan ")]), _c('c-list', {
    attrs: {
      "style-type": "disc",
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500",
      "font-family": "Roboto"
    }
  }, [_vm._l(_vm.item.education, function (edu, index) {
    return _c('c-list-item', {
      key: 'edu' + index,
      attrs: {
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(edu.level) + " " + _vm._s(edu.university === "0" ? edu.otherUniversity : _vm.getUniversityName(edu.university)) + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.item.education),
      expression: "isDataEmpty(item.education)"
    }]
  }, [_vm._v(" - ")])], 2)], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Spesialisasi / Keahlian ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.specialization ? Array.isArray(_vm.item.specialization) ? _vm.item.specialization.join(', ') : _vm.item.specialization : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Bahasa yang dikuasai ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$languages = _vm.item.languages) !== null && _vm$item$languages !== void 0 ? _vm$item$languages : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Usia Klien ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$clientAgeHan = _vm.item.clientAgeHandled) !== null && _vm$item$clientAgeHan !== void 0 ? _vm$item$clientAgeHan : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Masalah yang dikuasai ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.problemHandled ? Array.isArray(_vm.item.problemHandled) ? _vm.item.problemHandled.join(', ') : _vm.item.problemHandled : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Jam Layanan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.serviceHour ? "".concat(_vm.item.serviceHour, " WIB") : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Jam Aktif Chat ")]), _c('c-list', {
    attrs: {
      "style-type": "disc",
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500",
      "font-family": "Roboto"
    }
  }, [_vm._l(_vm.item.activeHour, function (active, index) {
    return _c('c-list-item', {
      key: 'active' + index
    }, [_vm._v(" " + _vm._s(active.startHour) + " - " + _vm._s(active.endHour) + " WIB ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.item.activeHour),
      expression: "isDataEmpty(item.activeHour)"
    }]
  }, [_vm._v(" - ")])], 2)], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Jadwal Layanan ")]), _c('c-list', {
    attrs: {
      "style-type": "disc",
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500",
      "font-family": "Roboto"
    }
  }, [_vm._l(_vm.item.serviceTime, function (service, index) {
    return _c('c-list-item', {
      key: 'service' + index
    }, [_vm._v(" " + _vm._s(service.title) + ": " + _vm._s(service.value ? service.value : '-') + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.item.serviceTime),
      expression: "isDataEmpty(item.serviceTime)"
    }]
  }, [_vm._v(" - ")])], 2)], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Pengalaman Bekerja ")]), _c('c-list', {
    attrs: {
      "style-type": "disc",
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "600",
      "font-family": "Roboto"
    }
  }, [_vm._l(_vm.workExperiences, function (work, index) {
    return _c('c-list-item', {
      key: 'work' + index
    }, [_c('c-box', {
      attrs: {
        "display": "inline-table"
      }
    }, [_c('c-text', [_vm._v(" " + _vm._s(work.companyName) + " ")]), _c('c-box', {
      attrs: {
        "font-weight": "400"
      }
    }, [_vm._v(" " + _vm._s(work.position) + " (" + _vm._s(work.startYear) + " - " + _vm._s(work.endYear) + ") ")])], 1)], 1);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.item.workExperiences),
      expression: "isDataEmpty(item.workExperiences)"
    }]
  }, [_vm._v(" - ")])], 2)], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kuota ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.quota) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Rating ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_c('StarRating', {
    attrs: {
      "increment": 0.5,
      "read-only": true,
      "star-size": 26,
      "text-class": "hidden"
    },
    model: {
      value: _vm.item.rating,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "rating", $$v);
      },
      expression: "item.rating"
    }
  })], 1)], 1)], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Nama Klien ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _vm.isHasClient ? _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "gap": "1",
      "mb": "20px"
    }
  }, [_c('c-input-group', {
    attrs: {
      "flex-grow": "1",
      "size": "md"
    }
  }, [_c('c-input-left-element', {
    staticStyle: {
      "height": "62px"
    },
    attrs: {
      "pos": "relative"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "h": "24px",
      "w": "24px",
      "pos": "absolute",
      "top": "50%",
      "left": "15px",
      "transform": "translateY(-50%)"
    }
  })], 1), _c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Cari nama klien",
      "bg": "none",
      "h": "62px",
      "border-radius": "20px",
      "pl": "50px",
      "font-size": "18px"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('c-box', {
    attrs: {
      "pos": "relative"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "p": "0",
      "h": "32px",
      "w": "32px"
    },
    on: {
      "click": _vm.onToggleFilter
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "fill": "#888888",
      "width": "24px",
      "height": "24px"
    }
  })], 1), _vm.isOpenFilter ? _c('c-box', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.onCloseFilter,
      expression: "onCloseFilter"
    }],
    attrs: {
      "pos": "absolute",
      "top": "36px",
      "right": "0",
      "w": "240px",
      "bg": "white",
      "border": "1px solid #C4C4C4",
      "rounded": "md"
    }
  }, [_c('c-text', {
    attrs: {
      "py": "4px",
      "text-align": "left",
      "px": "1rem",
      "color": "#333",
      "font-weight": "700",
      "font-size": "16px"
    }
  }, [_vm._v(" Status Program ")]), _vm._l(_vm.filterOptions, function (option) {
    return _c('c-box', {
      key: option.value
    }, [_c('c-divider', {
      attrs: {
        "border-color": "#C4C4C4",
        "m": "0",
        "p": "0"
      }
    }), _c('c-button', {
      attrs: {
        "rounded": "0",
        "variant": "ghost",
        "d": "flex",
        "w": "full",
        "justify-content": "space-between",
        "font-weight": "500",
        "font-size": "18px",
        "color": option.value == _vm.filter ? '#008C81' : '#888',
        "bg": option.value == _vm.filter ? '#C7F9E3' : '#FFF',
        "h": "48px"
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeFilter(option.value);
        }
      }
    }, [_vm._v(" " + _vm._s(option.label) + " "), option.value == _vm.filter ? _c('c-box', {
      attrs: {
        "height": ['14.66px', '21.99px'],
        "width": ['14.66px', '21.99px'],
        "min-height": ['14.66px', '21.99px'],
        "min-width": ['14.66px', '21.99px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-check.svg'),
        "fill": "#008C81"
      }
    })], 1) : _vm._e()], 1)], 1);
  })], 2) : _vm._e()], 1)], 1) : _vm._e(), _c('c-grid', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoadingClients,
      expression: "!isLoadingClients"
    }],
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "3"
    }
  }, _vm._l(_vm.clients, function (client) {
    return _c('CardClient', {
      key: client.id,
      attrs: {
        "item": client
      }
    });
  }), 1), _c('SkeletonCardClient', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoadingClients,
      expression: "isLoadingClients"
    }]
  }), _vm.clients.length == 0 ? _c('c-flex', {
    attrs: {
      "justify": "center",
      "align": "center",
      "p": "30px"
    }
  }, [_vm._v(" " + _vm._s(_vm.isHasClient ? 'Klient tidak ditemukan' : 'Tidak punya Klien') + " ")]) : _vm._e(), _vm.clients.length > 0 ? _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "py": "24px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px"
    }
  }, [_vm._v(" Show: ")]), _c('c-select', {
    attrs: {
      "min-w": "120px",
      "border-radius": "6px",
      "font-size": "14px",
      "ml": "13px",
      "size": "md"
    },
    on: {
      "change": _vm.onChangeRow
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }, [_c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(" 5 Rows ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 Rows ")]), _c('option', {
    attrs: {
      "value": "15"
    }
  }, [_vm._v(" 15 Rows ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" 20 Rows ")])])], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end",
      "w": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "p": "0",
      "mr": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.currentPage === 1
    },
    on: {
      "click": _vm.prevPage
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (page_) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: 'rgba(0, 140, 129, 0.3)',
            color: 'primary.400'
          }
        },
        expression: "{\n              ':hover': {\n                bg: 'rgba(0, 140, 129, 0.3)',\n                color: 'primary.400',\n              },\n            }"
      }],
      key: page_,
      attrs: {
        "p": "0",
        "m": "0",
        "w": "32px",
        "h": "32px",
        "min-w": "32px",
        "mx": "4px",
        "border-radius": "30px",
        "background-color": "rgba(0, 140, 129, 0.3)",
        "color": "primary.400",
        "bg": page_ === _vm.currentPage ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: page_
          })
        }
      }
    }, [_vm._v(" " + _vm._s(page_) + " ")]);
  }), _c('c-button', {
    attrs: {
      "p": "0",
      "ml": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.pages.length === _vm.page
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1) : _vm._e()], 1), _c('DeleteConfirm', {
    attrs: {
      "content": _vm.deletedText,
      "is-open": _vm.isOpen
    },
    on: {
      "on-delete": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }